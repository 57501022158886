import '../node_modules/photoswipe/dist/photoswipe.css';
import '../node_modules/photoswipe/dist/default-skin/default-skin.css';
import './main.scss';
import "@babel/polyfill";

import Swiper from 'swiper';
import CookiesEuBanner from 'cookies-eu-banner';
import _ from 'lodash';
import $ from 'jquery';
import 'popper.js';
import 'bootstrap';
import bsCustomFileInput from 'bs-custom-file-input'
import './js/photoswipe.js';

import Vue from 'vue';
//import NewsApp from './js/NewsApp.vue';
import Countable from 'countable';


require('alpinejs/dist/alpine-ie11.js');

var banner = new CookiesEuBanner(function(){
    (function(b,o,i,l,e,r){b.GoogleAnalyticsObject=l;b[l]||(b[l]=
    function(){(b[l].q=b[l].q||[]).push(arguments)});b[l].l=+new Date;
    e=o.createElement(i);r=o.getElementsByTagName(i)[0];
    e.src='https://www.google-analytics.com/analytics.js';
    r.parentNode.insertBefore(e,r)}(window,document,'script','ga'));
  ga('create', 'UA-29783754-10', 'auto');ga('send','pageview');ga('set', 'anonymizeIp', true);
});

window.addEventListener('load', () => {
  try {
    var photogalleryOptions = {
      shareButtons: localizedShareButtons
    };
  } catch (ReferenceError) {
    var photogalleryOptions = {};
  }

  $.extend(photogalleryOptions, {
    itemFilter: function () {
      return $(this).parents('.slick-cloned').length === 0;
    },
    captionSelector: '.caption'
  });

  $('.gallery').photogallery(photogalleryOptions);

  bsCustomFileInput.init();

  $('#nav-tab a').on('click', function (e) {
    e.preventDefault()
    $(this).tab('show')
  });


  var ThumbOptions = {
    slidesPerView: 2,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    breakpoints: {
      // < tablet
      769: {
        slidesPerView: 2,
      },
      // < desktop
      960: {
        slidesPerView: 3,
      }
    }
  }

  var GalleryThumbs = new Swiper('.gallery-thumbs', ThumbOptions);

  var GallerySwiper = new Swiper('.gallery-swiper', {
    slidesPerView: 1,
    speed: 500,
    navigation: {
      prevEl: '.gallery__arrow--prev',
      nextEl: '.gallery__arrow--next',
      disabledClass: 'gallery__arrow--disabled',
    },
    thumbs: {
      swiper: GalleryThumbs
    }
  });
/*
  $('.popup__links').on('click', function (e) {
    e.preventDefault();
    var modal = $(this).data("target");
    var ModalGalleryThumbs;
    var ModalGallerySwiper;

    $(modal).one('hidden.bs.modal', function (e) {
      try {
        ModalGalleryThumbs.destroy();
        ModalGallerySwiper.destroy();
      } catch (error) {

      }
      $(modal + ' .modal-body').html('');
    });

    var url = $(this).attr("href") + ' .main-wrapper .container';

    $(modal + ' .modal-body').load(
      url,
      function () {
        $(modal + ' .print-button').click(function (e) {
          e.preventDefault();

          var myPopup = window.open(url + "#print");

          return false;
        });

        try {
          ModalGalleryThumbs = new Swiper('.gallery-thumbs', ThumbOptions);

          ModalGallerySwiper = new Swiper('.gallery-swiper', {
            slidesPerView: 1,
            speed: 500,
            navigation: {
              prevEl: '.gallery__arrow--prev',
              nextEl: '.gallery__arrow--next',
              disabledClass: 'gallery__arrow--disabled',
            },
            thumbs: {
              swiper: ModalGalleryThumbs
            }
          });
        } catch (error) {

        }
      }
    );

    $(modal).modal("show");
  });
*/

  function toggleDropdown(e) {
    const _d = $(e.target).closest('.dropdown'),
      _m = $('.dropdown-menu', _d);
    setTimeout(function () {
      const shouldOpen = e.type !== 'click' && _d.is(':hover');
      _m.toggleClass('show', shouldOpen);
      _d.toggleClass('show', shouldOpen);
      $('[data-toggle="dropdown"]', _d).attr('aria-expanded', shouldOpen);
    }, e.type === 'mouseleave' ? 300 : 0);
  }

  $('body')
    .on('mouseenter mouseleave', '.dropdown', toggleDropdown)
    .on('click', '.dropdown-menu a', toggleDropdown);

  $('.navbar ul.navbar-nav > .dropdown > a[href]').click(function () {
    var dropdown = $(this).next('.dropdown-menu');
    /*
     * The dropdown can be non-existent
     * The dropdown can be already open by css
     * (for instance display: block from a custom :hover setting)
     * or a "show" class on the element which also sets a display: block;
     */
    if (dropdown.length == 0 || $(dropdown).css('display') !== 'none') {
      if (this.href) {
        location.href = this.href;
      }
    }
  });

  $('body').scrollspy({
    target: '#navbar-main'
  });

  if ($('.swiper-slideshow .swiper-slide').length > 1) {

    var gallerySwiper = new Swiper('.swiper-slideshow', {
      slidesPerView: 1,
      speed: 500,
      navigation: {
        prevEl: '.swiper-slideshow__arrow--prev',
        nextEl: '.swiper-slideshow__arrow--next',
        disabledClass: 'swiper-slideshow__arrow--disabled',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
        clickable: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
    });

    $('.swiper-slideshow').hover(function (params) {
      gallerySwiper.autoplay.stop();
    }, function (e) {
      gallerySwiper.autoplay.start();
    });
  }

  var file = document.getElementById("id_moodboard");
  if (file) {
    file.onchange = function () {
      if (file.files.length > 0) {

        document.getElementsByClassName('file-name')[0].innerHTML = file.files[0].name;

      }
    };
  }
});

(function () {
  function CountableField(textarea) {
    var countDisplay = document.getElementById(textarea.id + "_counter");
    var countDown = false;
    var minCount, maxCount;
    if (textarea != null && countDisplay != null) {
      minCount = textarea.getAttribute("data-min-count");
      maxCount = textarea.getAttribute("data-max-count");

      Countable.on(textarea, updateCharatersLeft);
    }

    function updateCharatersLeft(counter) {
      var count = counter["all"];
      var left = maxCount - count;
      countDisplay.getElementsByClassName("text-count-current")[0].innerHTML = left;
      if (left < 0)
        countDisplay.className = "text-count text-is-over-max";
      else
        countDisplay.className = "text-count";
    }
  }

  document.addEventListener('DOMContentLoaded', function (e) {
    ;
    [].forEach.call(document.querySelectorAll('[data-count]'), CountableField)
  })
})()
